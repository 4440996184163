import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const SeanLeighStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="Sean Leigh - Beejay Group - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>Sean Leigh - Beejay Group</h1>          
            <p>This is an opportunity to thank you Dave for all the care and attention you have put into me for the last couple of years, of all the challenges we have thrown at each other. I have always had the feeling that ‘I’ am at the forefront of your mind, if I am right and comfortable in the decisions I make in life then the Business Opportunities will follow - along with the Business Growth.</p>

           <p> I look back now at some of my initial struggles - from changes of my mind set, the early days of selling myself, to employment processes, management and selling the idea and the culture of the business and the values that I would like my team to aspire too - and smile. Although these were all struggles to overcome initially each step we have taken together has grown me as a person, it has expanded my mind-set and has allowed me to build a solid foundation for both myself and the business. I look at the challenges ahead - Business Ideas, New Opportunities, Investment, and Operational Processes, Growth of my team and the personal attributes of my team, Expansion, and so much more – rather than feel daunted by the task, I feel excited about the opportunity.</p>

            <p>I understand that this is not all down to you, it has taken a huge amount of effort and application - there was times when I questioned myself and wondered what the heck I was doing - but without some of your guidance, a little of your understanding, a fair dose of your knowledge, but most importantly your support I couldn’t have achieved the things I have so far, and not hoped to achieve the goals I have set for the future.</p>

            <p>You have said a number of times from Salesman to CEO - I have internally laughed as I think of a CEO as a pompous git (no offence to any CEO's out there), but as I learn more it is not the 'CEO' part but the concept of ownership that should be my aspiration, although I own the business am I truly the owner? Or am I only a very moderately paid employee that all the other employees come to with their problems - with each step we go through, I get closer to the Ownership goal.</p>

            <p>The last few months have been extremely tough for me personally - but they have taught me that just as in life business is never perfect, but rather than just accept it and run around in the same circles, these same imperfections afford us the opportunity to grow and challenge ourselves to be better and contribute to our chosen paths. I can say with confidence that I am glad to have you on the path beside me.</p>

            <p>Thanks again Dave</p>
        </div>
    </main>
  </Layout>

)


export default SeanLeighStoryPage